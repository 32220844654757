<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <modal-modifications
          :opened-modal-modifications.sync="openedModalModifications"
          :item="itemModData"
          :languages="languages"
          :statuses="statuses"
          :roles-options="rolesOptions"
          :stocks-options="stocksOptions"
        />
        <b-button
          v-if="item.type === 'variable'"
          type="button"
          variant="warning"
          class="open-modifications"
          @click="itemModData = { ...item }; openedModalModifications = true"
        >
          {{ $t('general.modifications') }}
        </b-button>
        <a
          :href="`${apiUrl}/products/${item.slug}`"
          target="_blank"
          class="btn back-to-list btn-outline-info view-on-front"
        >
          {{ $t('general.viewOnFront') }}
        </a>
        <b-button
          type="button"
          variant="outline-secondary"
          class="back-to-list"
          @click="toList"
        >
          {{ $t('general.back') }}
        </b-button>
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_h1.label')"
                        :label-for="'meta_h1.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_h1.' + language.code"
                          v-model="item.meta_h1[language.code]"
                          :state="errors && errors['meta_h1.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                          {{ errors['meta_h1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="item.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="item.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_keywords.label')"
                        :label-for="'meta_keywords.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_keywords.' + language.code"
                          v-model="item.meta_keywords[language.code]"
                          :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                          {{ errors['meta_keywords.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab
              :title="$t('admin.products.tabs.data.label')"
              @click="loadDataTab"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.categories.label')"
                    label-for="categories"
                    :state="errors && errors.categories ? false : null"
                  >
                    <v-select
                      id="categories"
                      v-model="item.categories"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryOptions"
                      :reduce="val => val.value"
                      :clearable="true"
                      :multiple="true"
                      input-id="categories"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.categories">
                      {{ errors.categories[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.top_category.label')"
                    label-for="categories"
                    :state="errors && errors.category_id ? false : null"
                  >
                    <v-select
                      id="category_id"
                      v-model="item.category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryOptionsTop"
                      :reduce="val => val.value"
                      :clearable="true"
                      :multiple="false"
                      input-id="category_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.category_id">
                      {{ errors.category_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.stickers.label')"
                    label-for="stickers"
                    :state="errors && errors.stickers ? false : null"
                  >
                    <v-select
                      id="stickers"
                      v-model="item.stickers"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stickersOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :multiple="true"
                      input-id="stickers"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stickers">
                      {{ errors.stickers[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.services.label')"
                    label-for="services"
                    :state="errors && errors.services ? false : null"
                  >
                    <v-select
                      id="services"
                      v-model="item.services"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="servicesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :multiple="true"
                      input-id="services"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.services">
                      {{ errors.services[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.sku.label')"
                    label-for="sku"
                  >
                    <b-form-input
                      id="sku"
                      v-model="item.sku"
                      :state="errors && errors.sku ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.sku">
                      {{ errors.sku[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.ean.label')"
                    label-for="ean"
                  >
                    <b-form-input
                      id="ean"
                      v-model="item.ean"
                      :state="errors && errors.ean ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.ean">
                      {{ errors.ean[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.indexing_status.label')"
                    label-for="indexing_status"
                    :state="errors && errors.indexing_status ? false : null"
                  >
                    <v-select
                      id="indexing_status"
                      v-model="item.indexing_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="indexing_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                      {{ errors.indexing_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="position"
                  >
                    <b-form-input
                      id="position"
                      v-model="item.position"
                      type="number"
                      :state="errors && errors.position ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.stock_status.label')"
                    label-for="stock_status_id"
                    :state="errors && errors.stock_status_id ? false : null"
                  >
                    <v-select
                      id="stock_status_id"
                      v-model="item.stock_status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statuses"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="stock_status_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stock_status_id">
                      {{ errors.stock_status_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.available_at.label')"
                    label-for="available_at"
                    :state="errors && errors.available_at ? false : null"
                  >
                    <b-form-datepicker
                      id="available_at"
                      v-model="item.available_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.available_at">
                      {{ errors.available_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.novelty_until.label')"
                    label-for="novelty_until"
                    :state="errors && errors.novelty_until ? false : null"
                  >
                    <b-form-datepicker
                      id="novelty_until"
                      v-model="item.novelty_until"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.novelty_until">
                      {{ errors.novelty_until[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('form.min_quantity.label')"
                    label-for="min_quantity"
                  >
                    <b-form-input
                      id="min_quantity"
                      v-model="item.min_quantity"
                      type="number"
                      :state="errors && errors.min_quantity ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.min_quantity">
                      {{ errors.min_quantity[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.images.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group :label="$t('form.avatar.label')">
                    <div class="mb-75">
                      <InitFileManager
                        field="avatar"
                        type="product_avatar"
                        :multiple="false"
                        @fm-selected="selectedAvatar"
                      />
                    </div>
                    <!-- media -->
                    <div class="media">
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>
                    </div>
                    <!--/ media -->
                    <div class="mt-75">
                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                >
                  <b-form-group :label="$t('form.gallery.label')">
                    <div class="mb-75">
                      <InitFileManager
                        field="gallery"
                        type="product_gallery"
                        :multiple="true"
                        @fm-selected="selectedGallery"
                      />
                    </div>
                    <!-- media -->
                    <draggable
                      v-model="item.gallery"
                      class="media"
                    >
                      <b-media-aside
                        v-for="(file, index) in item.gallery"
                        :key="index"
                      >
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="file.url"
                            height="80"
                          />
                        </b-link>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.gallery.splice(index, 1)"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                      </b-media-aside>
                    </draggable>
                    <!--/ media -->
                    <div class="mt-75">
                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.video.label')"
                    label-for="video"
                  >
                    <b-form-input
                      id="video"
                      v-model="item.video"
                      :state="errors && errors.code ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.video">
                      {{ errors.video[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.stocks.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(stock, index) in item.stocks"
                  :id="'stock' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.stocks.label')"
                      :label-for="'stocks.' + index + '.stock_id'"
                      :state="errors && errors['stocks.' + index + '.stock_id'] ? false : null"
                    >
                      <v-select
                        :id="'stocks.' + index + '.stock_id'"
                        v-model="stock.stock_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="stocksOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'stocks.' + index + '.stock_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.stock_id']">
                        {{ errors['stocks.' + index + '.stock_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.quantity.label')"
                      :label-for="'stocks.' + index + '.quantity'"
                    >
                      <b-form-input
                        :id="'stocks.' + index + '.quantity'"
                        v-model="stock.quantity"
                        :state="errors && errors['stocks.' + index + '.quantity'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.quantity']">
                        {{ errors['stocks.' + index + '.quantity'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeStock(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addStock"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.stocks"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.stocks[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.prices.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(price, index) in item.prices"
                  :id="'price' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.role.label')"
                      :label-for="'prices.' + index + '.role_id'"
                      :state="errors && errors['prices.' + index + '.role_id'] ? false : null"
                    >
                      <v-select
                        :id="'prices.' + index + '.role_id'"
                        v-model="price.role_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rolesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'prices.' + index + '.role_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.role_id']">
                        {{ errors['prices.' + index + '.role_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.price.label')"
                      :label-for="'prices.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'prices.' + index + '.price'"
                        v-model="price.price"
                        :state="errors && errors['prices.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.price']">
                        {{ errors['prices.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removePrice(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPrice"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.prices"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.prices[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.discounts.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(discount, index) in item.discounts"
                  :id="'discount' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      :label="$t('form.role.label')"
                      :label-for="'discounts.' + index + '.role_id'"
                      :state="errors && errors['discounts.' + index + '.role_id'] ? false : null"
                    >
                      <v-select
                        :id="'discounts.' + index + '.role_id'"
                        v-model="discount.role_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rolesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'discounts.' + index + '.role_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.role_id']">
                        {{ errors['discounts.' + index + '.role_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.percent.label')"
                      :label-for="'discounts.' + index + '.percent'"
                    >
                      <b-form-input
                        :id="'discounts.' + index + '.percent'"
                        v-model="discount.percent"
                        :type="`number`"
                        :step="`1`"
                        :min="`1`"
                        :max="`99`"
                        @input="setDiscountPercent(index, $event)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      :label="$t('form.price.label')"
                      :label-for="'discounts.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'discounts.' + index + '.price'"
                        :ref="'discounts' + index + 'price'"
                        v-model="discount.price"
                        :type="`number`"
                        :step="`1`"
                        :state="errors && errors['discounts.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.price']">
                        {{ errors['discounts.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.started_at.label')"
                      :label-for="'discounts.' + index + '.started_at'"
                      :state="errors && errors['discounts.' + index + '.started_at'] ? false : null"
                    >
                      <b-form-datepicker
                        :id="'discounts.' + index + '.started_at'"
                        v-model="discount.started_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.started_at']">
                        {{ errors['discounts.' + index + '.started_at'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.ended_at.label')"
                      :label-for="'discounts.' + index + '.ended_at'"
                      :state="errors && errors['discounts.' + index + '.ended_at'] ? false : null"
                    >
                      <b-form-datepicker
                        :id="'discounts.' + index + '.ended_at'"
                        v-model="discount.ended_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.ended_at']">
                        {{ errors['discounts.' + index + '.ended_at'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeDiscount(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addDiscount"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.discounts"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.discounts[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.payments.label')">
              <b-alert
                show
                :style="`info`"
                class="p-1"
              >
                Якщо налаштування методів оплати відсутні у товарі, він доступний до оплати всіма методами за-замовчуванням. <br>
                Якщо налаштування присутні - товар доступний до оплати лише за вказаними методами.
              </b-alert>
              <div class="repeater-form">
                <b-row
                  v-for="(method, index) in item.payment_methods"
                  :id="'method' + index"
                  :key="index"
                  ref="row"
                >
                  <template v-if="needCredits(method.payment_method_id)">
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.payment_method.label')"
                        :label-for="'payment_methods.' + index + '.payment_method_id'"
                        :state="errors && errors['payment_methods.' + index + '.payment_method_id'] ? false : null"
                      >
                        <v-select
                          :id="'payment_methods.' + index + '.payment_method_id'"
                          v-model="method.payment_method_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="paymentsOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'payment_methods.' + index + '.payment_method_id'"
                          @input="method.payment_method_gateway_id = null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['payment_methods.' + index + '.payment_method_id']">
                          {{ errors['payment_methods.' + index + '.payment_method_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.payment_method_gateway.label')"
                        :label-for="'payment_methods.' + index + '.payment_method_gateway_id'"
                        :state="errors && errors['payment_methods.' + index + '.payment_method_gateway_id'] ? false : null"
                      >
                        <v-select
                          :id="'payment_methods.' + index + '.payment_method_gateway_id'"
                          v-model="method.payment_method_gateway_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="paymentGatewaysOptions[`pm_${method.payment_method_id}`]"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'payment_methods.' + index + '.payment_method_gateway_id'"
                          :disabled="!paymentGatewaysOptions[`pm_${method.payment_method_id}`] || !paymentGatewaysOptions[`pm_${method.payment_method_id}`].length"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['payment_methods.' + index + '.payment_method_gateway_id']">
                          {{ errors['payment_methods.' + index + '.payment_method_gateway_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        :label="$t('form.parts.label')"
                        :label-for="'payment_methods.' + index + '.parts'"
                      >
                        <b-form-input
                          :id="'payment_methods.' + index + '.parts'"
                          :ref="'payment_methods' + index + 'parts'"
                          v-model="method.parts"
                          :type="`number`"
                          :step="`1`"
                          :state="errors && errors['payment_methods.' + index + '.parts'] ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['payment_methods.' + index + '.parts']">
                          {{ errors['payment_methods.' + index + '.parts'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </template>
                  <template v-else>
                    <b-col
                      cols="12"
                      md="5"
                    >
                      <b-form-group
                        :label="$t('form.payment_method.label')"
                        :label-for="'payment_methods.' + index + '.payment_method_id'"
                        :state="errors && errors['payment_methods.' + index + '.payment_method_id'] ? false : null"
                      >
                        <v-select
                          :id="'payment_methods.' + index + '.payment_method_id'"
                          v-model="method.payment_method_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="paymentsOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'payment_methods.' + index + '.payment_method_id'"
                          @input="method.payment_method_gateway_id = null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['payment_methods.' + index + '.payment_method_id']">
                          {{ errors['payment_methods.' + index + '.payment_method_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="5"
                    >
                      <b-form-group
                        :label="$t('form.payment_method_gateway.label')"
                        :label-for="'payment_methods.' + index + '.payment_method_gateway_id'"
                        :state="errors && errors['payment_methods.' + index + '.payment_method_gateway_id'] ? false : null"
                      >
                        <v-select
                          :id="'payment_methods.' + index + '.payment_method_gateway_id'"
                          v-model="method.payment_method_gateway_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="paymentGatewaysOptions[`pm_${method.payment_method_id}`]"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'payment_methods.' + index + '.payment_method_gateway_id'"
                          :disabled="!paymentGatewaysOptions[`pm_${method.payment_method_id}`] || !paymentGatewaysOptions[`pm_${method.payment_method_id}`].length"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['payment_methods.' + index + '.payment_method_gateway_id']">
                          {{ errors['payment_methods.' + index + '.payment_method_gateway_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </template>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removePayment(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>

                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPayment"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>
              <b-row
                v-if="errors && errors.payment_methods"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.payment_methods[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab @click="loadAttributes">
              <template #title>
                <feather-icon
                  v-if="item.type === 'variable'"
                  icon="InfoIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span>{{ $t('admin.products.tabs.attributes.label') }}</span>
              </template>

              <div class="repeater-form">
                <b-row
                  v-for="(attribute, index) in item.attributes"
                  :id="'attribute' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.attribute_id.label')"
                      :label-for="'attributes.' + index + '.attribute_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_id'"
                        v-model="attribute.attribute_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="attributesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_id'"
                        @input="loadAttributeValue($event, index)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                        {{ errors['attributes.' + index + '.attribute_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.attribute_value_id.label')"
                      :label-for="'attributes.' + index + '.attribute_value_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_value_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_value_id'"
                        v-model="attribute.attribute_value_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                        :disabled="!attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_value_id'"
                        :taggable="true"
                        :create-option="val => ({ value: `new_${val}`, label: `Добавить новое значение ${val}` })"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_value_id']">
                        {{ errors['attributes.' + index + '.attribute_value_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                    xs="6"
                  >
                    <b-form-group
                      :label="$t('form.is_link.label')"
                      :label-for="'attributes.' + index + '.is_link'"
                    >
                      <b-form-checkbox
                        :id="'attributes.' + index + '.is_link'"
                        v-model="attribute.is_link"
                        :state="errors && errors['attributes.' + index + '.is_link'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.is_link']">
                        {{ errors['attributes.' + index + '.' + '.is_link'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                    xs="6"
                  >
                    <b-form-group
                      :label="$t('form.is_variant.label')"
                      :label-for="'attributes.' + index + '.is_variant'"
                    >
                      <b-form-checkbox
                        :id="'attributes.' + index + '.is_variant'"
                        v-model="attribute.is_variant"
                        :state="errors && errors['attributes.' + index + '.is_variant'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.is_variant']">
                        {{ errors['attributes.' + index + '.' + '.is_variant'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeAttribute(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAttribute"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.attributes"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.sets.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(set, index) in item.sets"
                  :id="'set' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'sets.' + index + '.product1'"
                    >
                      <b-form-input
                        :id="'sets.' + index + '.product1'"
                        v-model="item.title.uk"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="1"
                  >
                    <b-form-group
                      :label="`.`"
                      :label-for="'sets.' + index + '.plus'"
                    >
                      <b-form-input
                        :id="'sets.' + index + '.plus'"
                        value="+"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'sets.' + index + '.entity_id'"
                      :state="errors && errors['sets.' + index + '.entity_id'] ? false : null"
                    >
                      <v-select
                        :id="'sets.' + index + '.entity_id'"
                        v-model="set.entity_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'sets.' + index + '.entity_id'"
                        :searchable="true"
                        @search="searchProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['sets.' + index + '.entity_id']">
                        {{ errors['sets.' + index + '.entity_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.price.label')"
                      :label-for="'sets.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'sets.' + index + '.price'"
                        v-model="set.price"
                        :state="errors && errors['sets.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['sets.' + index + '.price']">
                        {{ errors['sets.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeSet(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addSet"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.sets"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.sets[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.related.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(related, index) in item.relateds"
                  :id="'related' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'related.' + index + '.related_id'"
                      :state="errors && errors['related.' + index + '.related_id'] ? false : null"
                    >
                      <v-select
                        :id="'related.' + index + '.related_id'"
                        v-model="related.related_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'related.' + index + '.related_id'"
                        :searchable="true"
                        @search="searchProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['related.' + index + '.related_id']">
                        {{ errors['related.' + index + '.related_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeRelated(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addRelated"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.relateds"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.related[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.accessories.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(accessory, index) in item.accessories"
                  :id="'accessory' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'accessory.' + index + '.accessory_id'"
                      :state="errors && errors['accessory.' + index + '.accessory_id'] ? false : null"
                    >
                      <v-select
                        :id="'accessory.' + index + '.accessory_id'"
                        v-model="accessory.accessory_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'accessory.' + index + '.accessory_id'"
                        :searchable="true"
                        @search="searchProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['accessory.' + index + '.accessory_id']">
                        {{ errors['accessory.' + index + '.accessory_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeAccessory(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAccessory"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.accessories"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.accessories[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.documents.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(document, index) in item.documents"
                  :id="'document' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group :label="$t('form.file.label')">
                      <!-- media -->
                      <b-media no-body>
                        <b-media-aside
                          v-if="document.file"
                          style="min-height: 40px; width: 100%;"
                        >
                          <b-link>
                            <feather-icon
                              icon="FileIcon"
                              size="26"
                              class="mr-1"
                            />
                            {{ document.file ? document.file.path : '' }}
                          </b-link>
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="document.file = null"
                          >
                            {{ $t('general.reset') }}
                          </b-button>
                        </b-media-aside>

                        <b-media-body
                          v-else
                          class="mt-75 ml-75"
                        >
                          <InitFileManager
                            :field="'documents.' + index + '.file'"
                            type="document_file"
                            :multiple="false"
                            @fm-selected="selectedFile($event, document)"
                          />
                          <b-row
                            v-if="errors && errors['documents.' + index + '.file']"
                          >
                            <b-col cols="12">
                              <b-form-invalid-feedback :state="false">
                                {{ errors['documents.' + index + '.file'][0] }}
                              </b-form-invalid-feedback>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="errors && errors['documents.' + index + '.file.type']"
                          >
                            <b-col cols="12">
                              <b-form-invalid-feedback :state="false">
                                {{ errors['documents.' + index + '.file.type'][0] }}
                              </b-form-invalid-feedback>
                            </b-col>
                          </b-row>
                        </b-media-body>
                      </b-media>
                      <!--/ media -->
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('form.type.label')"
                      :label-for="'documents.' + index + '.type'"
                    >
                      <v-select
                        :id="'documents.' + index + '.type'"
                        v-model="document.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="documentTypes"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'documents.' + index + '.type'"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['documents.' + index + '.type']">
                        {{ errors['documents.' + index + '.type'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('form.action.label')"
                      :label-for="'documents.' + index + '.action'"
                    >
                      <v-select
                        :id="'documents.' + index + '.action'"
                        v-model="document.action"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="documentActions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'documents.' + index + '.action'"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['documents.' + index + '.action']">
                        {{ errors['documents.' + index + '.action'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeDocument(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addDocument"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.documents"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.documents[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'products-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import CategoryMixin from '@/mixins/CategoryMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ModalModifications from '@/views/catalog/products/ModalModifications.vue'

export default {
  components: {
    ModalModifications,
  },
  mixins: [CategoryMixin, GlobalMixin, EditorMixin],
  data() {
    return {
      item: null,
      oldCategoryId: null,
      apiUrl: process.env.VUE_APP_APP_URL,
      itemModData: {},
      openedModalModifications: false,
      languages: [],
      statuses: [],
      stockStatusOptions: [
        { label: this.$t('general.stock_statuses.available'), value: 'available' },
        { label: this.$t('general.stock_statuses.not_available'), value: 'not_available' },
        { label: this.$t('general.stock_statuses.waiting'), value: 'waiting' },
        { label: this.$t('general.stock_statuses.pre_order'), value: 'pre_order' },
      ],
      typeOptions: [
        { label: this.$t('general.product_types.simple'), value: 'simple' },
        { label: this.$t('general.product_types.variable'), value: 'variable' },
      ],
      documentTypes: [
        { label: this.$t('general.document_types.document'), value: 'document' },
        { label: this.$t('general.document_types.driver'), value: 'driver' },
        { label: this.$t('general.document_types.passport'), value: 'passport' },
      ],
      documentActions: [
        { label: this.$t('general.document_actions.download'), value: 'download' },
        { label: this.$t('general.document_actions.view'), value: 'view' },
      ],
      loading: {
        categories: false,
        stocks: false,
        roles: false,
        attributes: false,
      },
      categories: [],
      categoryOptions: [],
      stickersOptions: [],
      servicesOptions: [],
      stocksOptions: [],
      rolesOptions: [],
      paymentsList: [],
      paymentsOptions: [],
      paymentGatewaysOptions: [],
      attributesOptions: [],
      attributeValuesOptions: [],
      extensionOptions: [],
      productOptions: [],
      fmPath: '',
    }
  },
  computed: {
    categoryOptionsTop() {
      return _.filter(this.categoryOptions, cat => _.includes(this.item.categories, cat.value))
    },
  },
  watch: {
    'item.category_id': {
      handler(categoryId) {
        this.$nextTick(() => {
          if (categoryId && this.oldCategoryId !== categoryId) {
            this.addRecommendedAttributes(categoryId)
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })
    await this.$http.get('/api/admin/statuses')
      .then(response => {
        this.statuses = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get('/api/stocks', { params: { status: 'enabled', per_page: 100 } })
      .then(response => {
        this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get('/api/roles', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.rolesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get('/api/admin/payment_methods', { params: { mode: 'client', per_page: 100 } })
      .then(response => {
        this.paymentsList = response.data.data
        this.paymentsOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')

        const vm = this

        _.each(response.data.data, item => {
          vm.paymentGatewaysOptions[`pm_${item.id}`] = vm.$options.filters.transformForVSelect(item.gateways, 'id', 'title')
        })
      })

    await this.$http.get(`/api/admin/products/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        const products = _.reduce(data.sets, (result, set) => {
          result.push(set.entity)
          return result
        }, [])

        this.productOptions = this.$options.filters.transformForVSelect(products, 'id', 'title')

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'products-index' })
        }
      })
  },
  methods: {
    selectedFile(data, document) {
      document.file = data
    },
    selectedAvatar(data) {
      this.item.avatar = data
    },
    selectedGallery(data) {
      this.item.gallery = _.concat(this.item.gallery, data)
    },
    async searchProduct(query) {
      await this.$http.get('/api/admin/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          if (this.item.sets?.length) {
            _.each(this.item.sets, set => {
              if (set.entity_id) {
                this.$http.get(`/api/admin/products/${set.entity_id}`)
                  .then(res => {
                    this.productOptions.push({ label: res.data.data.title, value: set.entity_id })
                    this.productOptions = _.uniqBy(this.productOptions, 'value')
                  })
              }
            })
          }
        })
    },
    async loadDataTab() {
      if (!Object.keys(this.categoryOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/admin/fullcategories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categories = response.data
            this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
      if (!Object.keys(this.stickersOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/stickers', { params: { status: 'enabled', per_page: 100 } })
          .then(response => {
            this.stickersOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
      if (!Object.keys(this.servicesOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/services', { params: { per_page: 100 } })
          .then(response => {
            this.servicesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title_full')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
    },
    loadAttributes() {
      if (Object.keys(this.attributesOptions).length) {
        return
      }

      this.loading.attributes = false

      this.$http.get('/api/attributes', {
        params: {
          sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 200, relations: 'values.translations',
        },
      })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')

          const vm = this

          _.each(response.data.data, item => {
            vm.attributeValuesOptions[`attribute_${item.id}`] = vm.$options.filters.transformForVSelect(item.values, 'id', 'title')
          })

          this.loading.attributes = true
        })
        .catch(() => {
          this.loading.attributes = true
        })
    },
    async loadAttributeValue(id, index) {
      this.item.attributes[index].attribute_value_id = null

      if (this.attributeValuesOptions && _.has(this.attributeValuesOptions, `attribute_${id}`)) {
        return
      }

      const response = await this.$http.get(`/api/attributes/${id}/values`)

      this.$set(this.attributeValuesOptions, `attribute_${id}`, await this.$options.filters.transformForVSelect(response.data.data, 'id', 'title'))
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        type: this.item.type,
        video: this.item.video,
        category_id: this.item.category_id,
        categories: this.item.categories,
        stocks: this.item.stocks,
        stickers: this.item.stickers,
        services: this.item.services,
        prices: this.item.prices,
        discounts: this.item.discounts,
        attributes: this.item.attributes,
        avatar: this.item.avatar,
        gallery: this.item.gallery,
        status: this.item.status,
        indexing_status: this.item.indexing_status,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        sku: this.item.sku,
        ean: this.item.ean,
        min_quantity: this.item.min_quantity,
        position: this.item.position,
        available_at: this.item.available_at,
        novelty_until: this.item.novelty_until,
        stock_status_id: this.item.stock_status_id,
        sets: this.item.sets,
        relateds: this.item.relateds,
        accessories: this.item.accessories,
        documents: this.item.documents,
        payment_methods: this.item.payment_methods,
      }

      this.$http.put(`/api/admin/products/${this.item.id}`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Updated',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          router.replace({
            name: 'products-update',
            params: { id: this.item.id },
            query: { ...this.$route.query, ...{ updated: Math.floor(Date.now() / 1000) } },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    addStock() {
      this.item.stocks.push({
        quantity: 1,
        stock_id: null,
      })
    },
    removeStock(index) {
      this.item.stocks.splice(index, 1)
    },
    addPrice() {
      this.item.prices.push({
        price: 1,
        role_id: 2,
      })
    },
    removePrice(index) {
      this.item.prices.splice(index, 1)
    },
    addDiscount() {
      this.item.discounts.push({
        price: 1,
        percent: 0,
        started_at: new Date(),
        ended_at: new Date(),
        role_id: 2,
      })
    },
    removeDiscount(index) {
      this.item.discounts.splice(index, 1)
    },
    addPayment() {
      this.item.payment_methods.push({
        id: null,
        payment_method_id: null,
        payment_method_gateway_id: null,
        amount: 0,
        parts: 1,
      })
    },
    removePayment(index) {
      this.item.payment_methods.splice(index, 1)
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: null,
        attribute_value_id: null,
        is_link: false,
        is_variant: false,
      })
      this.attributeValuesOptions.push([])
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
      this.attributeValuesOptions.splice(index, 1)
    },
    addSet() {
      this.item.sets.push({
        price: 1,
        entity_id: null,
      })
    },
    removeSet(index) {
      this.item.sets.splice(index, 1)
    },
    addRelated() {
      this.item.relateds.push({
        entity_id: null,
      })
    },
    removeRelated(index) {
      this.item.relateds.splice(index, 1)
    },
    addAccessory() {
      this.item.accessories.push({
        accessory_id: null,
      })
    },
    removeAccessory(index) {
      this.item.accessories.splice(index, 1)
    },
    addDocument() {
      this.item.documents.push({
        file: null,
        type: 'document',
        action: 'download',
      })
    },
    removeDocument(index) {
      this.item.documents.splice(index, 1)
    },
    transformData(item) {
      const data = {
        id: item.id,
        slug: item.slug,
        type: item.type,
        video: item.video,
        category_id: item.category_id,
        avatar: this.$options.filters.singleMedia(item.avatar),
        gallery: this.$options.filters.multipleMedia(item.gallery),
        categories: item.categories,
        stocks: item.stocks,
        prices: [],
        discounts: [],
        payment_methods: [],
        attributes: [],
        stickers: item.stickers,
        services: item.services,
        status: item.status,
        indexing_status: item.indexing_status,
        sku: item.sku,
        ean: item.ean,
        min_quantity: item.min_quantity,
        position: item.position,
        available_at: item.available_at ? item.available_at : new Date(),
        novelty_until: item.novelty_until ? item.novelty_until : new Date(),
        stock_status_id: item.stock_status.id,
        modifications: item.modifications,
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        sets: [],
        relateds: [],
        accessories: [],
        documents: [],
      }
      this.oldCategoryId = item.category_id

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.meta_description[language.code] = translation ? translation.meta_description : null
        data.meta_h1[language.code] = translation ? translation.meta_h1 : null
        data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        data.meta_title[language.code] = translation ? translation.meta_title : null
        data.canonical[language.code] = translation ? translation.canonical : null
      })

      data.categories = _.map(data.categories, 'id')
      data.stickers = _.map(data.stickers, 'id')
      data.services = _.map(data.services, 'id')

      data.stocks = _.reduce(data.stocks, (result, stock) => {
        result.push({
          stock_id: stock.id,
          quantity: stock.quantity,
        })
        return result
      }, [])

      data.prices = _.reduce(item.prices, (result, price) => {
        result.push({
          id: price.id,
          role_id: price.role_id,
          price: price.price,
        })
        return result
      }, [])

      data.payment_methods = _.reduce(item.paymentMethods, (result, method) => {
        result.push({
          id: method.id,
          payment_method_id: method.payment_method_id,
          payment_method_gateway_id: method.payment_method_gateway_id,
          amount: method.amount,
          parts: method.parts,
        })
        return result
      }, [])

      data.discounts = _.reduce(item.discounts, (result, discount) => {
        result.push({
          id: discount.id,
          role_id: discount.role_id,
          price: discount.price,
          started_at: discount.started_at,
          ended_at: discount.ended_at,
        })
        return result
      }, [])

      data.attributes = _.reduce(item.attributes, (result, attribute) => {
        result.push({
          id: attribute.id,
          attribute_id: attribute.attribute_id,
          attribute_value_id: attribute.attribute_value_id,
          is_link: attribute.is_link,
          is_variant: attribute.is_variant,
        })
        return result
      }, [])

      data.sets = _.reduce(item.sets, (result, set) => {
        result.push({
          id: set.id,
          entity_id: set.entity_id,
          price: set.price,
        })
        return result
      }, [])

      data.relateds = _.reduce(item.relateds, (result, set) => {
        result.push({
          id: set.id,
          related_id: set.related_id,
        })
        return result
      }, [])

      data.accessories = _.reduce(item.accessories, (result, set) => {
        result.push({
          id: set.id,
          accessory_id: set.accessory_id,
        })
        return result
      }, [])

      data.documents = _.reduce(item.documents, (result, set) => {
        result.push({
          id: set.id,
          type: set.type,
          action: set.action,
          file: this.$options.filters.singleMedia(set.file),
        })
        return result
      }, [])

      // fmPath
      const catUrl = item.categories.length ? item.categories[0].slug : 'uncategorized'
      this.fmPath = `products/${catUrl}/${item.slug}`

      return data
    },
    setDiscountPercent(ref, ev) {
      const disc = (this.item.prices['0'].price - ((this.item.prices['0'].price * ev) / 100))
      this.item.discounts[ref].price = parseInt(disc, 10)
    },
    toList() {
      router.replace({
        name: 'products-index',
        query: { ...this.$route.query, ...{ updated: Math.floor(Date.now() / 1000) } },
      })
    },
    addRecommendedAttributes(categoryId) {
      const selectedCategory = _.find(this.categories, ['id', categoryId])
      const selectedCategoryAttributes = selectedCategory ? _.reduce(selectedCategory.recommendation_attributes, (result, attribute) => {
        result.push({
          attribute_id: attribute.attribute_id,
          attribute_value_id: null,
          is_link: false,
          is_variant: false,
        })
        return result
      }, []) : []

      if (!selectedCategoryAttributes.length) return

      this.confirm(() => {
        this.item.attributes = selectedCategoryAttributes
        this.attributeValuesOptions.push([])
      }, {
        text: 'Додати до товару характеристики, рекомендовані для цієї категорії?',
      })
    },
    needCredits(methodId) {
      return _.some(this.paymentsList, {
        id: methodId,
        type: 'part',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.back-to-list {
  position: absolute;
  top: -75px;
  right: -20px;
}

.view-on-front {
  position: absolute;
  top: -75px;
  right: 80px;
}

.open-modifications {
  position: absolute;
  top: -75px;
  right: 210px;
}
</style>
