<template>
  <b-sidebar
    id="opened-modal-modifications"
    :visible="openedModalModifications"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-modal-modifications', val)"
  >
    <template>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('general.modifications') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <b-form class="p-2">
        <!-- Form Actions -->
        <div class="d-flex mb-2">
          <b-button
            type="button"
            variant="warning"
            :class="item.modifications.length ? 'w-50 mr-2' : 'mr-2'"
            block
            @click="generateAll"
          >
            {{ $t('modifications.btn.generate.label') }}
          </b-button>
          <b-button
            v-if="item.modifications.length"
            type="button"
            variant="danger"
            class="w-50"
            block
            @click="deleteAll"
          >
            {{ $t('modifications.btn.delete.label') }}
          </b-button>
        </div>
        <!-- Form Actions -->

        <div
          v-if="item.modifications.length"
          class="accordion"
          role="tablist"
        >
          <Modification
            v-for="mod in item.modifications"
            :key="mod.id"
            :mid="mod.modification_id"
            :languages="languages"
            :statuses="statuses"
            :roles-options="rolesOptions"
            :stocks-options="stocksOptions"
          />
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Modification from '@/views/catalog/products/Modification.vue'

export default {
  components: {
    Modification,
  },
  mixins: [GlobalMixin],
  model: {
    prop: 'openedModalModifications',
    event: 'update:opened-modal-modifications',
  },
  props: {
    openedModalModifications: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    rolesOptions: {
      type: Array,
      required: true,
    },
    stocksOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-modal-modifications', false)
    },
    generateAll() {
      this.item.modifications = []
      this.$http.post(`/api/admin/products/${this.item.id}/modifications`, {
        text: this.item.text,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Created',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          this.$router.go(this.$router.currentRoute)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    deleteAll() {
      this.$http.delete(`/api/admin/products/${this.item.id}/modifications`, {
        text: this.item.text,
      })
        .then(() => {
          this.item.modifications = []
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Deleted',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
#opened-modal-modifications {
  width: 80rem;

  .btn-block + .btn-block {
    margin-top: 0 !important;
  }
}
</style>
